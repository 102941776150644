<template>
	<section class="dashboard dashboard--searches">
		<header class="dashboard__header">
			<h3 class="heading3">Searches</h3>
			<a v-if="false" href="#" class="dashboard__actions"></a>
            
            <div v-if="false" class="sort-section">
                <a class="sort-section__up" href="#"></a>
                <a class="sort-section__down" href="#"></a>
            </div>
		</header>

		<div class="dashboard__content">
			<div v-if="loading">Loading...</div>
			<div class="text-center" style="width: 100%" v-if="!loading && isEmpty">
				<h4 class="heading4 mb-4">Find The Perfect Startup</h4>
				<p>Use SwithPitch's advanced search to find startups that match your needs</p>
				<router-link
					to="/startups"
					type="button"
					class="btn btn-primary btn-lg"
				>
					Search Startups
				</router-link>
			</div>
			<ul class="dashboard__stats" v-if="!loading && !isEmpty">
				<li>
					<div class="dashboard__stats__counter">
						<animated-number
							:value="conductedSearchesCount"
							:duration="500"
							:round="true"
                            :formatValue="stringHelper.toLocale"
						/>
					</div>
					<p>{{stringHelper.getNoun('Search', conductedSearchesCount, 'Searches')}} conducted</p>
				</li>

				<li>
					<div class="dashboard__stats__counter">
						<animated-number
							:value="savedSearchesCount"
							:duration="500"
							:round="true"
                            :formatValue="stringHelper.toLocale"
						/>
					</div>
					<p>{{stringHelper.getNoun('Search', savedSearchesCount, 'Searches')}} saved</p>
				</li>
			</ul>

			<div class="dashboard__content__inner" v-if="!loading && !isEmpty">
				<h4 class="heading4">Saved searches</h4>
				<ul v-if="savedSearches.length > 0" class="rows-list rows-list--secondary rows-list--cards rows-list--cards-secondary">
					<li v-for="search in savedSearches" :key="search.id">
						<div class="rows-list--cards__content">
                            <div>
                                <h5 class="heading5"><a :href="'/'+search.search_type+'?query='+search.id">{{ search.name }}</a></h5>
                                <p>Created {{ search.created_at }} </p>
                            </div>
                            
                            <a v-if="false" href="#" class="link-edit-contact"></a>

						</div>
                        <div class="rows-list--cards__footer flex-column">
                            <template v-if="search.auto_scout">
                                <div v-if="search.search_type !== 'ecosystems'" class="rows-list--cards__enabled">
                                    Auto-Scout enabled
                                </div>
                            </template>
                            <template v-else>
                                <div v-if="search.search_type !== 'ecosystems'" class="rows-list--cards__disabled">
                                    Auto-Scout disabled
                                </div>
                            </template>
                            <template v-if="search.type == 'personal'">
                                <div class="rows-list--cards__private">
                                    Only visible to me
                                </div>
                            </template>
                            <template v-else>
                                <div class="rows-list--cards__shared">
                                    Shared with team
                                </div>
                            </template>
                        </div>
					</li>
				</ul>
                <div v-if="savedSearches.length == 0">
                    <p>You have no saved searches.</p>
                    <p><a :href="'/startups'">Search for startups</a> and save your search to easily jump back in from here</p>
                </div>
			</div>
        </div>
    </section>
</template>
<script>
import AnimatedNumber from 'animated-number-vue';
import dashboard from '@/services/dashboard.service';

export default {
	data() {
		return {
			loading: false,
            savedSearches: [],
            savedSearchesCount: 0,
			conductedSearchesCount: 0
		};
	},
	name: 'Searches',
	props: {
        filters: Object,
	},
	watch: {
        filters() {
            this.updateAll();
        },
    },
	methods: {
		async updateAll() {
			this.loading = true;

			await Promise.all([
				this.fetchSavedSearches(),
				this.fetchSavedSearchesCount(),
				this.fetchConductedSearchesCount()
			]);

			this.loading = false;
		},
        async fetchConductedSearchesCount() {
            const response = await dashboard.getWidgetData('ConductedSearchesCount', this.filters);
            this.conductedSearchesCount = response.data
        },
        async fetchSavedSearchesCount() {
            const response = await dashboard.getWidgetData('SavedSearchesCount', this.filters);
			this.savedSearchesCount = response.data
		},
        async fetchSavedSearches() {
            const response = await dashboard.getWidgetData('SavedSearches', this.filters);
            this.savedSearches = response.data;
        }
    },
	mounted() {
		this.$store.watch(state => {
            if (state.accountType !== 'premium') {
                void this.updateAll();
            }
		});

		void this.updateAll();
	},
	computed: {
		isEmpty() {
			return (
				this.savedSearches.length === 0 &&
				+this.savedSearchesCount === 0 &&
				+this.conductedSearchesCount === 0
			);
		}
	},
	components: {
		AnimatedNumber
	}
}
</script>
